import * as React from "react"
import { Container } from "react-bootstrap"
import { Link, navigate } from "@reach/router";

// Components
import Divider from "../Divider";
// import { menuLinks } from "../common/menuUtils";

// Styles
import "./BreadCrumbs.scss"


const BreadCrumbs = ( {  menu_data } ) => {

    //console.log("Menu_Data", menu_data);


    return (
        <div className="bread-crumbs-wrapper">
            <Container>
                <div className="bread-crumbs-content">
                    <Link to={"/"} className="">
                        Home
                    </Link>
                    <Divider delimiter="/" />
                    <Link to={`/${menu_data?.Primary_URL}/`} className="">
                        {menu_data?.Page_Name}
                    </Link>
                </div>
            </Container>
        </div>
    )
}

export default BreadCrumbs
